<template style="display: inline-table;">
	<!-- <v-row> -->
	<v-card>
		<v-row>
			<v-col cols="12">
				<s-toolbar
					:label="'Armado de Canastilla - ' + items.DedDescription"
					dark
					:color="'#BAB6B5'"
					close
					@close="close()"
					:save="!disabled"
					@save="save()"
					class="mb-0"
				></s-toolbar>
			</v-col>

			<v-col cols="12">
				<v-container class="pb-2">
					<v-row justify="center">
						<!-- <v-col lg="2" class="s-col-form" cols="12" sm="4" md="4">
							<s-text label="Nro Proceso" readonly />
						</v-col> -->
						<v-col lg="2" class="s-col-form" cols="12" sm="4" md="2">
							<s-select-definition
								:def="1181"
								label="Destino" 
								v-model="filter.TypeDestiny"
								:dgr="74"
								@change="loadLayettePallet()"
								:disabled="!btnloader"
								/>
						</v-col>
						<v-col lg="2" class="s-col-form" cols="12" sm="4" md="2">
							<s-select-definition
								label="Frente Canastilla"
								:def="1260"
								v-model="items.TypeFrontLayyete"
								:readonly="disabled"
							/>
						</v-col>

						<v-col lg="2" class="s-col-form" cols="12" sm="4" md="2">
							<s-select-definition
								label="Tiempo de Hidrotermico"
								:def="1261"
								v-model="items.TypeTimeHidro"
								:readonly="disabled"
							/>
						</v-col>

						<v-col lg="2" class="s-col-form" cols="12" sm="4" md="2">
							<s-select-definition
								label="Estado Canastilla"
								:def="1263"
								v-model="items.TypeState"
								:readonly="disabled"
							/>
						</v-col>
					</v-row>
				</v-container>
			</v-col>

			<v-col cols="12" >
				<v-row dense justify="center">
					<v-col cols="6" >
						Parihuelas Disponibles
						<v-chip style="margin: 0px" small :color="'info'"> </v-chip>
						<!-- <v-btn
							v-if="items.TypeState !== 3"
							class="mx-4"
							fab
							dark
							color="warning"
							small
							@click="newItem()"
						>
							<v-icon dark>
								mdi-plus
							</v-icon>
						</v-btn> -->
						<v-btn
							class="mx-4"
							rounded
							dark
							color="info"
							small
							@click="viewDetails()"
						>
							Ver detalle
						</v-btn><span style="margin-right: 15px">
							<v-switch v-if="!modeGrap" style="display: inline-block;" label="Scannear QR" v-model="scann"></v-switch>
						</span>
						<!-- <span>
							<v-switch style="display: inline-block;" label="Modo Grafico" v-model="modeGrap" @click="removeTmp()"></v-switch>
						</span> -->
						
					</v-col>
				</v-row>
				<v-row   style="margin: auto" > 
							<v-col
								align="center"
								cols="12"
								lg="8"
								md="8"
								v-if="scann"
							>
								<s-scanner-qr
									:config="configScann"
									autofocus
									return-object
									@onValue="onValue($event)"
									:readonly="false"
								></s-scanner-qr>
							</v-col>
				</v-row>
				<v-row justify="center"> 
					<v-col sm="3"   >
						<!-- @input="loadLayettePallet()" -->
						<s-select-campaign-area
							label="Campaña"
							:def="1230"
							v-model="filter.CmaID"
							typeArea="3"
							full
							
							@change="loadLayettePallet()"
							:disabled="!btnloader"
						/>
					</v-col>
					<v-col sm="3"  >
						<s-select-definition
							label="Planta Procesadora"
							:def="1230"
							v-model="filter.TypeProcessingPlant"
							@change="loadLayettePallet()"
							:disabled="!btnloader"
						/>
						<!-- @input="loadLayettePallet()" -->
					</v-col>
					 
				</v-row>
				  <v-row>
					<v-col>
						
				<v-card dense v-if="!modeGrap">
					<v-card-title>
	
						<b style="width: 100%; ">
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details
								style="margin-top: -30px;"
							></v-text-field>
						</b>
					</v-card-title>
					<!-- show-select -->
					<v-data-table
						:loading="!btnloader"
						item-key="LlpID"
						dense
						:items-per-page="5"
						:items="itemsLayettePallet"
						:headers="headersLayettePallet"
						v-model="selectedLayettePallet"
						@click:row="rowClickPallet"
						:search="search"
					>
						<template v-slot:item.Actions="{ item }">
							<v-btn
								@click="showPosition(item)"
								x-small
								elevation="0"
								:disabled="disabled"
								fab
								dark
								color="warning"
							>
								<!-- <v-icon style="font-size: 16px !important"
									>mdi-plus</v-icon
								> -->
								<v-icon dark>
									mdi-plus
								</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-card>
					</v-col>
				  </v-row>
			</v-col>

			<v-col cols="12" v-if="!modeGrap">
				<v-toolbar dense>
					<v-toolbar-title
						>Parihuelas Asignadas
						<v-chip style="margin: 0px" small :color="'info'">
							{{ sumTotal }}
						</v-chip>
						<!-- <v-btn
							v-if="items.TypeState !== 3"
							class="mx-4"
							fab
							dark
							color="error"
							small
							@click="removeItem()"
						>
							<v-icon dark>
								mdi-minus
							</v-icon>
						</v-btn> -->
					</v-toolbar-title>
				</v-toolbar>
				<v-card dense >
					<v-data-table
						item-key="Line"
						dense
						:items="itemsLayetteAssign"
						:headers="headersLayetteAssign"
						hide-default-footer
						disable-sort
					>
						<template v-slot:item.Actions="{ item }">
							<v-btn
								@click="removeItem(item)"
								x-small
								color="error"
								elevation="0"
								block
								:disabled="disabled"
							>
								<v-icon style="font-size: 16px !important"
									>fas fa-times</v-icon
								>
							</v-btn>
						</template>

						<template v-slot:item.LydPositionInitial="{ item }" v-if="!disabled">
							<s-text  v-model="item.LydPositionInitial"></s-text>
						</template>

						<template v-slot:item.LydPositionFin="{ item }" v-if="!disabled">
							<s-text v-model="item.LydPositionFin"></s-text>
						</template>
 
					</v-data-table>
				</v-card>
			</v-col>

			<v-col cols="12" v-if="modeGrap">
				<v-card flat :disabled="disabled">
					<v-row>
						<v-col align="center" cols="12" lg="12" md="12">
							<drag-drop
								:dropzones="itemsPositionAll"
								:originalData="itemsLayetteAssign"
								:dropzonesTitle="'UBICACIÓN DE TARIMAS EN CANASTILLA'"
								:inPlace="true"
								:enableSave="true"
								@save="saveDrap"
							>
								<template #dd-card="{ cardData }">
									<custom-card-layette
										:data="cardData"
									/>
								</template>
							</drag-drop>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog
			v-model="openDialog"
			v-if="openDialog"
			transition="dialog-bottom-transition"
		>
			<create-layette-new-detail
				:items="selectedLayettePallet"
				@close="closeDetail()"
			>
			</create-layette-new-detail>
		</v-dialog>

		<v-dialog persistent v-model="openPosition" v-if="openPosition" width="500">
			<v-card>
				<v-col>
					<v-row>
						<h2 class="mt-6 ml-3">Posición de Tarima en canastilla</h2>
						<v-spacer></v-spacer>
						<v-btn
							class="mt-3 mr-3"
							@click="openPosition = false"
							small
							fab
							color="error"
							><i style="font-size:16px;" class="fas fa-times"></i
						></v-btn>
					</v-row>

					<v-row>
						<v-col class="pb-0" cols="12">
							<s-select 
								:items="itemsPositionAll"
								item-text="LypDescription"
								item-value="LypID"
								@input="inputPosition($event)"
								v-model="objPosition"
								return-object
							></s-select>
						</v-col>
						<!-- <v-col class="pb-0" cols="6">
							<s-text label="Posición Inicio" number :min="1" v-model="LydPositionInitial"></s-text>
						</v-col>

						<v-col class="pb-0"  cols="6">
							<s-text label="Posición Fin" number v-model="LydPositionFin"> </s-text>
						</v-col> -->
					</v-row>
					<v-col>
						<v-row justify="center" class="pt-5">
							<v-spacer></v-spacer>
							<v-btn class="ml-3" dark color="primary" @click="newItem(itemAdd)"
								>Guardar</v-btn
							>
						</v-row>
					</v-col>
				</v-col>
			</v-card>
		</v-dialog>
	</v-card>

	

	<!-- </v-row> -->
</template>
<script>
	
	import _sLayetteService from "../../../services/FreshProduction/PrfLayetteService";
	import _sGenParamService from "../../../services/General/ParameterService";
	import CreateLayetteNewDetail from "./CreateLayetteNewDetail";
	import CustomCardLayette from "./CustomCardLayette.vue";
	import SSelectCampaignArea from "../../../components/Utils/SSelectCampaignArea.vue";
 

	export default {
		props: {
			items: {
				require: true,
				default: null,
			},
			TypeDestinyName: String,
		},
		components: { CreateLayetteNewDetail, CustomCardLayette, SSelectCampaignArea },
		watch: { 
			itemsLayetteAssign() {
				this.sumTotal = this.isSubtractTotal();
			},
			LydPositionInitial(){
				
				this.LydPositionFin = parseInt(this.LydPositionInitial - 1) + parseInt(this.itemAdd.LlpQuantityJaba)
			}
			
		},
		data: () => ({
			filter: {},
			btnloader: true,
			TypeProcessingPlant: 1,
			itemsLayettePallet: [],
			objPosition: [],
			headersLayettePallet: [
				{text: "Acciones", value: "Actions", width: 30, align: "center"},
				{
					text: "N° Tarima",
					value: "ScfCode",
					sortable: false,
					width: "50px",
					
				},
				{
					text: "Parihuela Nro. ",
					value: "LlpID",
					sortable: false,
					width: "50px",
					
				},
				{
					text: "Lotes Producción",
					value: "RcfID",
					sortable: false,
					width: "50px",
				},
				{
					text: "Calibre",
					value: "TypeCaliberName",
					sortable: false,
					width: "50px",
				},

				{
					text: "Cant. Jabas",
					value: "LlpQuantityJaba",
					sortable: false,
					width: "50px",
				},
				{
					text: "Cultivo",
					value: "TypeCultiveName",
					sortable: false,
					width: "50px",
				},
				{
					text: "Tipo Cultivo",
					value: "TypeCropName",
					sortable: false,
					width: "50px",
				},
				{
					text: "Variedad",
					value: "VrtName",
					sortable: false,
					width: "50px",
				},
				
				{
					text: "Calidad",
					value: "IsReworkName",
					sortable: false,
					width: "50px",
				},
				{
					text: "Destino",
					value: "TypeDestinyName",
					sortable: false,
					width: "50px",
				},
			],
			selectedLayettePallet: [],

			itemsLayetteAssign: [],
			headersLayetteAssign: [
				{text: "Acciones", value: "Actions", width: 50},
				{
					text: "Parihuela Nro. ",
					value: "LlpID",
					sortable: false,
					width: "50px",
				},
				{
					text: "Lotes Producción",
					value: "RcfID",
					sortable: false,
					width: "50px",
				},
				{
					text: "Calibre",
					value: "TypeCaliberName",
					sortable: false,
					width: "50px",
				},
				{
					text: "Cant. Jabas",
					value: "LlpQuantityJaba",
					sortable: false,
					width: "50px",
				},
				{
					text: "Cultivo",
					value: "TypeCultiveName",
					sortable: false,
					width: "50px",
				},
				{
					text: "Tipo Cultivo",
					value: "TypeCropName",
					sortable: false,
					width: "50px",
				},
				{
					text: "Variedad",
					value: "VrtName",
					sortable: false,
					width: "50px",
				},
				{
					text: "Posición",
					value: "LypDescription",
					sortable: false,
					width: "50px",
				},
				/* {
					text: "Posicíón Inicial",
					value: "LydPositionInitial",
					sortable: false,
					width: "50px",
				},
				{
					text: "Posición Fin",
					value: "LydPositionFin",
					sortable: false,
					width: "50px",
				}, */
			],
			itemsLayetteAssignRemoved: [],

			sumTotal: 0,
			valParamMax: 0,
			disabled: false,
			search: "",
			openDialog: false,
			removedTmp: [],
			repeated: {},
			scann: false,
			configScann: {},
			openPosition:false,
			LydPositionInitial: 0,
			LydPositionFin: 0,
			itemAdd: {},
			modeGrap:false,
			itemsPositionAll: [
				/* {
					name: "Posicion 01",
					children: []
				},
				{
					name: "Posicion 02",
					children: []
				},
				{
					name: "Posicion 03",
					children: []
				},
				{
					name: "Posicion 04",
					children: []
				},
				{
					name: "Posicion 05",
					children: []
				},
				{
					name: "Posicion 06",
					children: []
				}, */
			],
			receivedTmp: [],
		}),

		

		created() {
			this.loadLayettePallet();
			this.initialize();

			this.items.TypeState == 3
				? (this.disabled = true)
				: (this.disabled = false);

			this.loadPositonAll()
		},

		mounted() {
			_sGenParamService
				.search({ PrmName: "CanastillaTotalJabas" }, this.$fun.getUserID())
				.then(resp => {
					if (resp.status == 200) {
						this.valParamMax = resp.data.PrmValue;
					}
				});
		},

		methods: {

			inputPosition(item)
			{
				this.itemAdd.LypID = item.LypID;
				this.itemAdd.LypDescription = item.LypDescription;
				
			},

			loadPositonAll(){
					console.log('aaaaaaaaaa ', this.items);
					_sLayetteService.listLayettePosition({LytID: this.items.LytID, TypeLayetteID: this.items.TypeLayetteNumber == null ? this.items.DedID : this.items.TypeLayetteNumber}, this.$fun.getUserID()).then(resp => {
						if(resp.status == 200){
							console.log('posiciones ', resp.data);
							resp.data.forEach(element => {
								element.name = element.LypDescription
							});

							this.itemsPositionAll = resp.data

							if(this.itemsPositionAll.length > 0){
								this.itemsPositionAll.forEach(element => {

									if(element.children.length > 0){
										element.children.forEach(children => {
												this.receivedTmp.push(children);

												
										});
										
									}
									
								});

							}

						}
					})
			},
			close() {
				this.$emit("close");
				//this.$destroy();
			},

			closeDetail() {
				this.openDialog = false;
			},

			initialize() {
				_sLayetteService
					.GetLayetteDetail(
						{ LytID: this.items.LytID },
						this.$fun.getUserID(),
					)
					.then(r => {
						if (r.status == 200) {
							r.data.forEach(element => {
								element.done = false
							});
							this.itemsLayetteAssign = r.data;
							console.log(this.itemsLayetteAssign);
							this.orderDetails();
						}
					});
					
			},

			loadLayettePallet() {
				 //samir

				this.itemsLayettePallet = [];
				console.log("Prubeaaaa",this.filter);
				  
 				/*if (isNaN(this.filter.TypeDestiny) || isNaN(this.filter.CmaID) || isNaN(this.filter.TypeProcessingPlant)) {
					return;
				}*/
				this.btnloader = false;

				console.log("Prubeaa222",this.filter);

				_sLayetteService.GetPallet(this.filter,this.$fun.getUserID()).then(r => {
					this.btnloader = true;
					if (r.status == 200) {
						this.itemsLayettePallet = r.data;
						console.log('ddddd ', r.data);
					}
				});
			},

			rowClickPallet: function(item, row) {
				this.selectedLayettePallet = [item];
			},


			orderDetails() {
				let i = 0;
				this.itemsLayetteAssign.map(e => {
					i++;
					e.Line = i;
				});
			},

			newItem(item) {


				if(this.openPosition){

					if(item.LypID <= 0 || item.LypID == null || item.LypID == undefined){
						this.$fun.alert("Error, Ingrese Posicion de tarima", "warning");
						return;
					}
					
					item.LypID = this.objPosition.LypID;

				}else{
					item.LydPositionInitial = 1
					item.LydPositionFin = this.valParamMax
				}

				
				var total = this.sumTotal + item.LlpQuantityJaba

				if (total > this.valParamMax) {
					this.$fun.alert(
						"Error, La Cantidad maxima de Jabas por Canastilla es: " +
							this.valParamMax,
						"warning",
					);
					return;
				}

				let exist = false;
				this.itemsLayetteAssign.forEach((element) => {

					if(item.LypID == element.LypID ) {
						exist = true;
						this.$fun.alert("Error, La tarima ya esta en uso", "warning");
						
					}
				});
				this.itemsLayetteAssign.forEach((element) => {				
					if (item.LlpID == element.LlpID) {
						exist = true;
						this.$fun.alert("Parihuela Repetida", "warning");
					}

					/* if (item.VrtID !== element.VrtID) {
						exist = true;
						this.$fun.alert("No se puede armar Parihuela con diferente variedad", "warning");
					} */

					/* if (item.TypeCultive !== element.TypeCultive) {
						exist = true;
						this.$fun.alert("No se puede armar Parihuela con diferente Cultivo", "warning");
					} */

					/* if (item.TypeCrop !== element.TypeCrop) {
						exist = true;
						this.$fun.alert("No se puede armar Parihuela con diferente Tipo de cultivo", "warning");
					} */
				});

				if (exist == false) {
					var Line = this.itemsLayetteAssign.length + 1
					item.Line = Line

					this.itemsLayetteAssign.push(item);
					
					this.itemsLayetteAssign.forEach(element => {
						element.SecStatus = 1;
						element.UsrCreateID = this.$fun.getUserID();
					});

					this.openPosition = false;
					
					//agregar o quitar en tiempo real => solicitado por mirian y carlos
					this.itemsLayettePallet = this.itemsLayettePallet.filter(element => {
						return element.LlpID != item.LlpID
					} )
					//fin
				}
				
				
			},

			removeItem(item) {

				if (this.itemsLayetteAssign.length > 0) {
					
					if(item.LydID > 0){
						this.removedTmp.push(this.itemsLayetteAssign.filter((x) => x.Line == item.Line)[0]);
						this.removedTmp.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemsLayetteAssign = this.itemsLayetteAssign.filter((x) => x.Line != item.Line);
					this.itemsLayettePallet.push(item);
					//this.itemsLayetteAssignRemoved = [...this.itemsLayetteAssign, ...this.removedTmp]
					this.orderDetails();
				}
			},

			isSumaTotal() {
				var total = 0;
				this.itemsLayetteAssign.forEach(element => {
					total += parseInt(element.LlpQuantityJaba);
				});

				return total;
			},

			isSubtractTotal() {
				var total = 0;
				this.itemsLayetteAssign.forEach(element => {
					total += parseInt(element.LlpQuantityJaba);
				});
				return total;
			},

			save() {
				if (this.sumTotal > this.valParamMax) {
					this.$fun.alert(
						"Error, La Cantidad maxima de Jabas por Canastilla es: " +
							this.valParamMax,
						"warning",
					);
					return;
				}

				if (this.sumTotal <= 0) {
					this.$fun.alert(
						"Error, La Cantidad debe ser superior a 0 ",
						"warning",
					);
					return;
				}

				let temp = {};
				this.itemsLayetteAssign.forEach(element => {

					temp = {
						TypeCrop: element.TypeCrop,
						VrtID: element.VrtID,
						TypeCultive: element.TypeCultive,
						TypeDestiny : element.TypeDestiny
					};
				});
 
				this.items.TypeDestiny = temp.TypeDestiny;
				this.items.TypeCrop = temp.TypeCrop;
				this.items.TypeCultive = temp.TypeCultive;
				this.items.VrtID = temp.VrtID;
				this.items.TypeLayetteNumber = this.items.DedID;
				this.items.Secstatus = 1;
				this.items.UsrCreateID = this.$fun.getUserID();

				this.items.itemsDetail = [...this.itemsLayetteAssign, ...this.removedTmp]

				

				this.items.itemsDetail.forEach(element => {
					element.LydState = this.items.TypeState;
				});

				console.log("Guardar", this.items);
				this.$fun
					.alert("Esta seguro de Guardar ? ", "question")
					.then(val => {
						if (val.value) {
							_sLayetteService
								.save(this.items, this.$fun.getUserID())
								.then(r => {
									this.$fun.alert(
										"Registro guardado correctamente",
										"success",
									);
									this.close();
								});
						}
					});
			},

			viewDetails() {
				this.openDialog = true;
			},

			onValue(val) {
				if(this.itemsLayettePallet.length > 0){
					var obj = this.itemsLayettePallet.find(x => x.LlpID == val);
					
					this.showPosition(obj)
					console.log(obj);
				}else{
					this.$fun.alert("No se encontraron registros", "warning")
				}
				
			},

			showPosition(item){
				//this.newItem(item)
				if(item.LlpQuantityJaba < this.valParamMax ){

					this.itemAdd = item
					this.openPosition = true

					console.log("probando",this.itemAdd);
				}else{
					this.newItem(item)
				}
				
				
			},


			saveDrap(received) {
				/* console.log("Received:", received); */

				if (received.originalBucket.length > 0) {
					received.originalBucket.forEach((element) => {
						element.SecStatus = 100;
						element.UsrCreateID = this.$fun.getUserID();
						element.LypID = null;	

						_sLayetteService
							.updatePosition(element, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.$fun.alert(
										"Cambios guardados correctamente",
										"success"
									);
								}
							}); 
					});
				}

				if (received.dropzones.length > 0) {
					received.dropzones.forEach((element) => {
						element.children.forEach((children) => {
							children.LypID = element.LypID;
							children.SecStatus = 100;
							children.UsrCreateID = this.$fun.getUserID();

							console.log("children", children);

							_sLayetteService
								.updatePosition(children, this.$fun.getUserID())
								.then((resp) => {
									this.$fun.alert(
										"Cambios guardados correctamente",
										"success"
									);
								});

						});
					});
				}
			},

			removeTmp(){

				if(this.modeGrap){
					if (this.receivedTmp.length > 0) {
						this.receivedTmp.forEach((element) => {

							this.itemsLayetteAssign = this.itemsLayetteAssign.filter(
								x => x.LydID != element.LydID,
							);
							console.log("this.receivedTmp", element);
						});
					}
				}else{
					this.initialize()
				}
				
			}
		},
	};
</script>
